import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
} from "react-native";
import AxiosContext from '../components/AxiosContext';
import axios from 'axios';
import { globalStyles } from '../constants/GlobalStyles';
import { PublicComponent } from '../components/public/PublicComponent';

export default function PublicScreen({ navigation, route }: any) {
  
  const getPublicScreenFeed = () => {
    
  };
  
  return (
    <View style={globalStyles.container}>
      <PublicComponent navigation={navigation} route={route}></PublicComponent>
    </View>
  );
}
