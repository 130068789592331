import axios from "axios";
import { ImagePickerAsset } from "expo-image-picker";
import { IMessage } from "react-native-gifted-chat";
import { BlockUserRequest, ReportedPublicMessage } from "../../types/oarTypes";

export const getPublicFeedForUser = () => {
	return axios
		.get(`publics`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded public data.');
		});
};

export function blockPublicUser(blockUserRequest: BlockUserRequest, userId: string) {
  return axios
		.post(`publics/block`, blockUserRequest)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully created block public user request.');
		});
}

export const reportPublicMessage = (reportedMessage: ReportedPublicMessage) => {
	return axios
		.post(`publics/reportMessage`, reportedMessage)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully reported message.');
		});
};

export const savePublicImages = (groupId : string, images: ImagePickerAsset[]) => {
  return axios
		.post(`groups/${groupId}/uploadChatFile`, images)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully saved images data.');
		});
}
