import React, { Fragment, useRef, useState } from "react";
import { seaBlueColor } from "../../constants/Colors";
import { Modal, TouchableOpacity } from "react-native";
import {
  StyleSheet,
  Text,
  View
} from 'react-native';
import { Input, Icon } from '@rneui/themed';


import { globalStyles } from "../../constants/GlobalStyles";
import moment from "moment";
import { Calendar } from "react-native-calendars";

export default function DatePicker({ route, navigation, minDate, maxDate, isOpen, onDateChange }) {
  const [selectedStartDate, selectedEndDate] = useState('');
  const startDate = selectedStartDate ? selectedStartDate.toString() : '';
  const endDate = selectedEndDate ? selectedEndDate.toString() : '';

  return (
    <>
      <View style={[globalStyles.modalView, { display: isOpen ? "flex" : "none", zIndex: isOpen ? 0 : 1, maxWidth: 75 }]}>
        <Modal animationType="slide" visible={isOpen} transparent={true}>
          <View style={[globalStyles.container, { display: isOpen ? "flex" : "none", zIndex: isOpen ? 1 : 0 }]}>
              <Calendar
              current={minDate.toString()}
              onDayPress={onDateChange}
              headerStyle={{padding:10}}
              theme={{ todayBackgroundColor: seaBlueColor, todayTextColor: 'white', selectedDayBackgroundColor: seaBlueColor }}
              />
          
          </View>
        </Modal>
      </View>
    </>
  );
}