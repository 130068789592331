import { Pressable, View, Text, TouchableOpacity, Keyboard } from "react-native";
import moment from 'moment';
import 'moment-recur';
import React, { useEffect, useState } from "react";
import { Avatar, CheckBox, Input, Switch } from '@rneui/themed';
import { globalStyles } from "../../constants/GlobalStyles";
import { LoadingSpinnerComponent } from "../../components/LoadingSpinner";
import { seaBlueColor } from "../../constants/Colors";
import DatePicker from "../calendar/DatePicker";
import DropDownPicker from "react-native-dropdown-picker";
import { EventHoursInDay, EventLengthOptions, EventMinutesToChoose, EventRecurranceInterval, LifeOarEvent, LifeOarEventState, LifeOarEventTypes, LifeOarLogEvent } from "../../types/oarTypes";
import DayOfWeekSelector from "../calendar/DayOfWeekSelector";
import { saveNewEvent } from "../../services/Events/eventService";
import UserInfoContext from "../UserInfoContext";
import { sortArrayByProperty } from "../../helpers/arrayHelpers";
import * as GroupsService from "../../services/Groups/groupsService";

export function AddEventComponent({ navigation, route }) {
  let eventType = route.params.groupId == null ? 0 : 1
  let groupId = route.params.groupId == null ? null : route.params.groupId

  const todaysDate = moment().toLocaleString();
  const startDate = moment().format('YYYY-MM-DD');
  const [isLoading, setIsLoading] = useState(false);
  const startDateInput = React.useRef(null);
  const startTimeInput = React.useRef(null);
  const minDate = new Date();
  const maxDate = new Date(minDate.getFullYear() + 5, minDate.getMonth(), minDate.getDay())

  const [isRecurringEvent, setIsRecurringEvent] = useState(false);
  const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
  const [formattedStartDate, setFormattedStartDate] = useState('')
  const [formattedEndDate, setFormattedEndDate] = useState('')
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [startDateSelected, setStartDateSelected] = useState(false);
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState<string>('');

  const [onLocationPickerOpen, setOnLocationPickerOpen] = useState(false);
  const [locationPickerDropdownValue, setLocationPickerDropdownValue] = useState<string>(null);
  const [locationPickerItems, setLocationPickerItems] = useState<any[]>();

  const [onStartHourPickerOpen, setOnStartHourPickerOpen] = useState(false);
  const [startHourPickerDropdownValue, setStartHourPickerDropdownValue] = useState<number>();
  const [startHourPickerItems, setStartHourPickerItems] = useState<any[]>(EventHoursInDay);

  const [onStartMinutePickerOpen, setOnStartMinutePickerOpen] = useState(false);
  const [startMinutePickerDropdownValue, setStartMinutePickerDropdownValue] = useState<number>();
  const [startMinutePickerItems, setStartMinutePickerItems] = useState<any[]>(EventMinutesToChoose);

  const [onRecurranceIntervalPickerOpen, setOnRecurranceIntervalPickerOpen] = useState(false);
  const [recurranceIntervalPickerDropdownValue, setRecurranceIntervalPickerDropdownValue] = useState<number>();
  const [recurranceIntervalPickerItems, setRecurranceIntervalPickerItems] = useState<any[]>(EventRecurranceInterval);

  const [onLengthPickerOpen, setOnLengthPickerOpen] = useState(false);
  const [lengthPickerDropdownValue, setLengthPickerDropdownValue] = useState<number>();
  const [lengthPickerItems, setLengthPickerItems] = useState<any[]>(EventLengthOptions);

  const [mainParticipantOpen, setMainParticipantOpen] = useState(false);
  const [mainParticipantDropdownValue, setMainParticipantDropdownValue] = useState();
  const [mainParticipantPickerItems, setMainParticipantPickerItems] = useState<any[]>([]);

  const [isAllDayEventSelected, setIsAllDayEventSelected] = useState(false);

  const [isAMSelected, setIsAMSelected] = useState(false);
  const [isPMSelected, setIsPMSelected] = useState(true);

  const { userInfo } = React.useContext(UserInfoContext);

  useEffect(() => {
    loadMembers().then((results: any[]) => {
      var mappedResults = results.map(x => ({
        value: x.objectId,
        label: `${x.name}`
      })).sort(sortArrayByProperty)

      setMainParticipantPickerItems(mappedResults);
      setIsLoading(false);
    })
  }, [navigation])

  const toggleIsRecurringSwitch = (value) => {
    setIsRecurringEvent(value);
  };

  const loadMembers = async () => {
    return GroupsService.getGroupUsers(groupId)
  }

  const onAMPMPressed = (nameOfMeridianSelected: string) => {
    switch (nameOfMeridianSelected) {
      case "AM": {
        setIsAMSelected(true);
        setIsPMSelected(false);
        break;
      }
      case "PM": {
        setIsPMSelected(true);
        setIsAMSelected(false);
        break;
      }
    }
  }

  const onStartDateChange = (selectedDate) => {
    const newDate = moment(selectedDate.dateString)
    setSelectedStartDate(newDate.local().format("MM/DD/YYYY"))
    var duration = newDate.valueOf() - minDate.valueOf()
    var days = newDate.diff(minDate, "days")
    setFormattedStartDate(`${newDate.local().format("MM/DD/YYYY")} (${days} days from now)`)
    setStartDateSelected(true);
    setStartDatePickerOpen(false);
    Keyboard.dismiss();
    startDateInput.current.blur();
    startTimeInput.current.focus();
  }

  const onEndDateChange = (selectedDate: moment.Moment) => {
    Keyboard.dismiss();
    setSelectedEndDate(selectedDate.utc().format())
    var duration = selectedDate.valueOf() - minDate.valueOf()
    var days = selectedDate.diff(minDate, "days")
    setFormattedEndDate(`${selectedDate.format("MM/DD/YYYY")} (${days} days from now)`)
    setEndDateSelected(true);
    setEndDatePickerOpen(false)
  }

  const handleEventNameChange = (newName: string) => {
    setNewEventTitle(newName);
  }

  const getStartDateTimeUTC = () => {
    var startDateTimeString = `${selectedStartDate}T${isPMSelected == true ? startHourPickerDropdownValue == 12 ? startHourPickerDropdownValue : startHourPickerDropdownValue + 12 : startHourPickerDropdownValue == 12 ? startHourPickerDropdownValue + 12 : startHourPickerDropdownValue}:${startMinutePickerDropdownValue == 0 ? '00' : startMinutePickerDropdownValue}:00`
    var startDateTimeLocal = moment(startDateTimeString, "MM-DD-YYYY hh:mm:ss")
    return moment.utc(startDateTimeLocal).toISOString();
  }

  const createEvent = () => {
    if (newEventTitle !== null) {
      var imGoing: Array<string> = []
      var imMaybe: Array<string> = []
      var imDeclined: Array<string> = []
      imGoing.push(userInfo.objectId);
      var newEvent: LifeOarEvent = {
        title: newEventTitle,
        isRecurring: isRecurringEvent,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        type: eventType,
        isAllDay: isAllDayEventSelected,
        ownerId: userInfo.objectId,
        going: imGoing,
        maybeGoing: imMaybe,
        declined: imDeclined,
        groupId: groupId,
        startDateTime: getStartDateTimeUTC().toString(),
        state: LifeOarEventState.New,
        locationId: locationPickerDropdownValue,
        reminderMinsPrior: lengthPickerDropdownValue,
        lengthInMins: lengthPickerDropdownValue,
        recurringPattern: recurranceIntervalPickerDropdownValue,
        mainParticipant: mainParticipantDropdownValue
      }

      saveNewEvent(newEvent).then((results) => {
        if (eventType == 0) {
          navigation.navigate('Schedules')
        }
        else {
          navigation.navigate('GroupDetail', { 'groupId': groupId, 'component': 'Schedule' })
        }
      });
    }
  }

  const onStartDatePickerFocus = () => {
    if (!startDateSelected) { // only open the modal if date is not selected
      setStartDatePickerOpen(true);
    }
  }

  const onMainParticipantOpen = () => {

  }

  const onEndDatePickerFocus = () => {
    if (!endDateSelected) { // only open the modal if date is not selected
      setEndDatePickerOpen(true);
    }
  }

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <View style={globalStyles.centeredContainer}>
        <View style={[globalStyles.formContainer, {}]}>
          <Pressable style={globalStyles.centeredContainer}>
            <Input style={globalStyles.textInput} placeholder="Event name" maxLength={35} value={newEventTitle} onChangeText={handleEventNameChange}
              inputContainerStyle={globalStyles.textInputContainerStyle} placeholderTextColor={"black"} />
          </Pressable>
          {eventType != 0 &&
            <View style={[globalStyles.dropDownContainer, { zIndex: mainParticipantOpen ? 1 : 0 }]}>
              <DropDownPicker open={mainParticipantOpen}
                itemKey="value"
                value={mainParticipantDropdownValue}
                items={mainParticipantPickerItems}
                setOpen={setMainParticipantOpen}
                setValue={setMainParticipantDropdownValue}
                setItems={setMainParticipantPickerItems}
                onOpen={onMainParticipantOpen}
                placeholder="Select main participant"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                multiple={false}
              />
            </View>
          }
          <Pressable style={globalStyles.centeredContainer}>
            <Input ref={startDateInput} style={[globalStyles.textInput, { justifyContent: "center", display: startDatePickerOpen ? "none" : "flex", zIndex: startDatePickerOpen ? 0 : 1 }]}
              placeholder='Start date' placeholderTextColor={"black"} inputContainerStyle={globalStyles.textInputContainerStyle}
              value={formattedStartDate} onFocus={onStartDatePickerFocus} />
          </Pressable>

          <View ref={startTimeInput} style={[globalStyles.dropDownContainer, { maxWidth: "32%", flexDirection: "row", zIndex: onStartHourPickerOpen || onStartMinutePickerOpen ? 10 : 0 }]}>
            <View style={[globalStyles.centeredContainer, { flexDirection: "row", flex: 1, alignContent: "center", justifyContent: "center" }]}>
              <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", alignContent: "flex-start" }}>
                <Text style={{ flexDirection: "row", fontWeight: "bold", justifyContent: "flex-start", alignItems: "flex-start", alignContent: "flex-start" }}>Start Time: </Text>
              </View>
              <DropDownPicker open={onStartHourPickerOpen}
                value={startHourPickerDropdownValue}
                items={startHourPickerItems}
                setOpen={setOnStartHourPickerOpen}
                setValue={setStartHourPickerDropdownValue}
                setItems={setStartHourPickerItems}
                placeholder="Hour"
                style={{
                  borderColor: 'lightgrey'
                }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
              />
              <Text>:</Text>
              <DropDownPicker open={onStartMinutePickerOpen}
                value={startMinutePickerDropdownValue}
                items={startMinutePickerItems}
                setOpen={setOnStartMinutePickerOpen}
                setValue={setStartMinutePickerDropdownValue}
                setItems={setStartMinutePickerItems}
                placeholder="Mins"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
              />
              <Pressable onPress={() => onAMPMPressed("AM")}>
                <Avatar rounded title="AM" titleStyle={{ color: isAMSelected == true ? "white" : "black" }} size={30} containerStyle={{ marginRight: 5, marginLeft: 10, backgroundColor: isAMSelected == true ? seaBlueColor : "lightgrey" }} />
              </Pressable>
              <Pressable onPress={() => onAMPMPressed("PM")}>
                <Avatar rounded title="PM" titleStyle={{ color: isPMSelected == true ? "white" : "black" }} size={30} containerStyle={{ marginRight: 5, backgroundColor: isPMSelected == true ? seaBlueColor : "lightgrey" }} />
              </Pressable>
            </View>
          </View>

          <View style={[globalStyles.dropDownContainer, { maxWidth: "50%", flexDirection: "row", zIndex: onLengthPickerOpen ? 10 : 0 }]}>
            <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", alignContent: "flex-start" }}>
              <Text style={{ flexDirection: "row", fontWeight: "bold", justifyContent: "flex-start", alignItems: "flex-start", alignContent: "flex-start" }}>Event Length: </Text>
            </View>
            <DropDownPicker open={onLengthPickerOpen}
              value={lengthPickerDropdownValue}
              items={lengthPickerItems}
              setOpen={setOnLengthPickerOpen}
              setValue={setLengthPickerDropdownValue}
              setItems={setLengthPickerItems}
              placeholder="Length"
              style={{ borderColor: 'lightgrey' }}
              dropDownContainerStyle={{ borderColor: 'lightgrey' }}
            />
          </View>
          {/* <View style={[globalStyles.dropDownContainer, { zIndex: onLocationPickerOpen ? 1 : 0 }]}>
            <DropDownPicker open={onLocationPickerOpen}
              value={locationPickerDropdownValue}
              items={locationPickerItems}
              setOpen={setOnLocationPickerOpen}
              setValue={setLocationPickerDropdownValue}
              setItems={setLocationPickerItems}
              placeholder="Location"
              style={{ borderColor: 'lightgrey' }}
              dropDownContainerStyle={{ borderColor: 'lightgrey' }}
            />
          </View> */}
          <View style={[globalStyles.centeredContainer, { flexDirection: 'row', flexWrap: 'nowrap', paddingLeft: 10 }]}>
            <Text>Recurring:</Text>
            <Text style={{ paddingRight: 15, fontWeight: 'bold' }}>{isRecurringEvent == true ? "Yes" : "No"}</Text>
            <Switch disabled={false} color={seaBlueColor} value={isRecurringEvent} style={{ transform: [{ scaleX: 1.0 }, { scaleY: 1.0 },] }} onValueChange={(value) => toggleIsRecurringSwitch(value)} />
            <Pressable style={[globalStyles.container, { display: "flex", zIndex: startDatePickerOpen ? 0 : 1 }]}>
              <DatePicker route={route} navigation={navigation}
                isOpen={startDatePickerOpen} minDate={minDate} maxDate={maxDate} onDateChange={onStartDateChange} 
                
                >

              </DatePicker>
            </Pressable>
            <Pressable>
              <DatePicker route={route} navigation={navigation} isOpen={endDatePickerOpen} minDate={minDate} maxDate={maxDate} onDateChange={onEndDateChange} ></DatePicker>
            </Pressable>
            <CheckBox checked={isAllDayEventSelected} title="All day event" checkedColor={seaBlueColor} onPress={() => setIsAllDayEventSelected(!isAllDayEventSelected)}
            />
          </View>
          {isRecurringEvent == true &&
            <>
              <Pressable style={[globalStyles.container, { display: "flex", zIndex: endDatePickerOpen ? 0 : 1 }]}>
                <Input ref={startDateInput} style={[globalStyles.textInput, { justifyContent: "center", zIndex: endDatePickerOpen ? 0 : 1 }]}
                  placeholder='End date (optional)' placeholderTextColor={"black"} inputContainerStyle={globalStyles.textInputContainerStyle}
                  value={formattedEndDate} onFocus={onEndDatePickerFocus} />
              </Pressable>
              <View style={[globalStyles.dropDownContainer, { zIndex: onRecurranceIntervalPickerOpen ? 10 : 0 }]}>
                <DropDownPicker open={onRecurranceIntervalPickerOpen}
                  value={recurranceIntervalPickerDropdownValue}
                  items={recurranceIntervalPickerItems}
                  setOpen={setOnRecurranceIntervalPickerOpen}
                  setValue={setRecurranceIntervalPickerDropdownValue}
                  setItems={setRecurranceIntervalPickerItems}
                  placeholder="Recurrence Interval"
                  style={{ borderColor: 'lightgrey' }}
                  dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                />
              </View>
              {recurranceIntervalPickerDropdownValue == 1 &&
                <DayOfWeekSelector></DayOfWeekSelector>
              }
            </>
          }
          <Pressable style={globalStyles.saveBtn} onPress={createEvent}>
            <Text style={globalStyles.loginText}>Create</Text>
          </Pressable>
        </View>
      </View>
    )
  }
}
