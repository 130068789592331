import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import * as signalR from '@microsoft/signalr';
import { Avatar } from '@rneui/base/dist/Avatar/Avatar';
import { Badge, Divider } from '@rneui/themed';
import ObjectID from 'bson-objectid';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Pressable, Text, TextStyle, TouchableOpacity, View, Image, Platform } from 'react-native';
import { Bubble, GiftedChat, IMessage, Send } from 'react-native-gifted-chat';
import { seaBlueColor } from '../../constants/Colors';
import { globalStyles } from '../../constants/GlobalStyles';
import { sortArrayByProperty } from '../../helpers/arrayHelpers';
import { getInitialsFromName } from '../../helpers/stringHelpers';
import * as ChatService from '../../services/Chat/chatService';
import * as GroupService from '../../services/Groups/groupsService';
import * as UserService from '../../services/Users/userService';
import { BanUserFromGroupRequest, BlockUserRequest, ReportedMessage } from '../../types/oarTypes';
import { LoadingSpinnerComponent } from '../LoadingSpinner';
import UserInfoContext from '../UserInfoContext';
import { AlbumRecommendationListComponent } from './AlbumRecommendationListComponent';
import VideoPlayer, { type VideoPlayerRef } from 'react-native-video-player';
import Video from 'react-native-video';



export function ChatComponent({ route, navigation }) {
  const { userInfo } = React.useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isSentimentModalVisible, setIsSentimentModalVisible] = useState(false);
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [isMessageActionModalVisible, setIsMessageActionModalVisible] = useState(false);
  const [suggestions, setSuggestions] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any[]>([]);
  const [groupLocations, setGroupLocations] = useState<any[]>([]);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [messageToTakeActionOn, setMessageToTakeActionOn] = useState<IMessage>();
  const [banUserFromGroupRequest, setBanUserFromGroupRequest,] = useState<BanUserFromGroupRequest>();
  const [blockUserRequest, setBlockUserRequest,] = useState<BlockUserRequest>();
  const [messageToImposeSentimentOn, setMessageToImposeSentimentOn] = useState<any>();
  const playerRef = useRef<VideoPlayerRef>(null);


  var wssConnection: signalR.HubConnection;

  useEffect(() => {

    var groupId = route.params.groupId;
    loadGroupUsers(route.params.groupId).then((results: any[]) => {
      var mappedResults = results.map(x => ({
        value: x.objectId,
        label: x.name
      })).sort(sortArrayByProperty)
      setGroupUsers(mappedResults);
      loadChatHistoryForGroup(route.params.groupId);
    })

    loadGroupLocations(route.params.groupId).then((results: any[]) => {
      var mappedResults = results.map(x => ({
        value: x._id,
        label: x.title
      })).sort(sortArrayByProperty)
      setGroupLocations(mappedResults);
    })

    wssConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl("https://api.lifeoar.io/groups/ws")
      .withAutomaticReconnect()
      .build();

    // .withUrl("https://api.lifeoar.io/groups/ws")

    startConnection().then((res) => {
      wssConnection.invoke("joinGroupChat", groupId).catch((err) => console.log(err))
    })

    wssConnection.on("groupChatMessage", (newMessages: IMessage[]) => {
      var notMyMessages = newMessages.filter(mess => mess.user._id != userInfo.ObjectID);
      setMessages(previousMessages => GiftedChat.append(previousMessages, newMessages))
    })

    wssConnection.on("groupBotChatMessage", (newMessages: IMessage[]) => {
      setMessages(previousMessages => GiftedChat.append(previousMessages, newMessages))
    })

    wssConnection.on("groupMessageModerated", (moderatedMessage: IMessage) => {
      setMessages(previousMessages => previousMessages.filter(mess => mess._id != moderatedMessage._id))
    })

    wssConnection.on("groupChatMessageSentiment", (updatedMessage) => {
      loadChatHistoryForGroup(groupId);
    })

    setIsLoading(false);
  }, [route, navigation])

  const startConnection = async () => {
    try {
      if (wssConnection.state != signalR.HubConnectionState.Connected) {
        await wssConnection.start();
        console.log("SignalR Connected.");
        setTimeout(startConnection, 5000);
      }
    } catch (err) {
      console.log(err);
      setTimeout(startConnection, 5000);
    }
  }

  const loadChatHistoryForGroup = async (groupId: string) => {
    ChatService.getGroupChatHistory(groupId).then((results) => {
      setMessages(results);
    })
  }

  const loadGroupUsers = async (groupId: string) => {
    return GroupService.getGroupUsers(groupId)
  }

  const loadGroupLocations = async (groupId: string) => {
    return GroupService.getGroupLocations(groupId)
  }

  const onGenerateId = (messages = []) => {
    return new ObjectID().toString();
  }

  const onRenderAvatar = (name: string): React.ReactNode => {
    return <Avatar title={getInitialsFromName(name)} rounded size={25}
      containerStyle={{ backgroundColor: seaBlueColor }} />
  }

  const onPressReportMessage = async () => {
    if (messageToTakeActionOn) {
      console.log(`Reporting message with message id: ${messageToTakeActionOn._id}`)
      var payload: ReportedMessage = {
        groupId: route.params.groupId,
        reporterId: userInfo.objectId,
        reportedMessage: messageToTakeActionOn
      };
      await GroupService.reportGroupMessage(payload);
      setIsMessageActionModalVisible(false)
    }
  }

  const onPressBanUserFromGroup = async () => {
    if (banUserFromGroupRequest) {
      // TODO: Make sure person requesting someone to be banned is a group Owner.
      console.log(`Processing ban user request from group: ${banUserFromGroupRequest.groupId} and user id: ${banUserFromGroupRequest.userIdToBan} and requestor id: ${banUserFromGroupRequest.requestorId}`)
      await GroupService.requestGroupUserBan(banUserFromGroupRequest);
      setIsMessageActionModalVisible(false)
    }
  }

  const onPressBlockUser = async () => {
    if (blockUserRequest) {
      console.log(`Processing block user request from user: ${blockUserRequest.requestorId} to block user id: ${blockUserRequest.userIdToBlock}.`)
      await UserService.blockUser(blockUserRequest, userInfo.objectId);
      setIsMessageActionModalVisible(false)
    }
  }

  const parsePatterns = (linkStyle: TextStyle) => [
    {
      pattern: new RegExp(`@(?:${groupUsers.map(u => u.label).join('|')})\\b`),
      style: { fontWeight: 'bold' },
      onPress: (username) => console.log(`Pressed on ${username}`),
    },
    {
      pattern: new RegExp(`#(?:${groupLocations.map(u => u.label).join('|')})\\b`),
      style: { fontWeight: 'bold' },
      onPress: (location) => console.log(`Pressed on location: ${location}`),
    },
  ];

  const handleSelectImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
      allowsMultipleSelection: false,
    });

    if (!result.canceled) {
      if (result.assets[0].uri.startsWith("file:")) {
        let uri = result.assets[0].uri
        const fileContent = await FileSystem.readAsStringAsync(result.assets[0].uri, { encoding: FileSystem.EncodingType.Base64 });
        const fileExtension = uri.substring(uri.lastIndexOf(".") + 1, uri.length)
        result.assets[0].uri = `data:image/${fileExtension};base64,${fileContent}`
      }

      ChatService.saveChatImages(route.params.groupId, result.assets).then((results) => {
        const fileExtension = results[0].substring(results[0].lastIndexOf(".") + 1, results[0].length)
        var newMessages = new Array<IMessage>()
        let newMessage: IMessage = {
          _id: new ObjectID().toString(),
          image: fileExtension == "mp4" ? null : results[0],
          video: fileExtension == "mp4" ? results[0] : null,
          text: inputValue,
          createdAt: new Date(),
          user: {
            _id: userInfo.objectId,
            name: userInfo.name
          }
        }
        newMessages.push(newMessage);
        onSend(newMessages);
      })
    }
  };

  const handleMentionPress = (name) => {
    const newInputValue = inputValue.replace(/@\S*\s?/g, `@${name} `);
    setInputValue(newInputValue);
    setIsUserModalVisible(false);
  };

  const handleLocationPress = (location) => {
    const newInputValue = inputValue.replace(/#\S*\s?/g, `#${location} `);
    setInputValue(newInputValue);
    setIsLocationModalVisible(false);
  };

  const onTextChanged = (name: string) => {
    if (name.endsWith("#")) {
      setIsLocationModalVisible(true)
    }
    else if (name.endsWith("@")) {
      setIsUserModalVisible(true)
    }
    setInputValue(name)
  }

  const onRenderSend = (props: any) => {
    return (
      <View style={{ flexDirection: 'row-reverse', alignItems: 'center' }}>
        <FontAwesome5 name="camera" size={22} style={{ paddingRight: 10 }} onPress={handleSelectImage} />
        <Send {...props}></Send>
      </View>
    )
  }

  const renderBubble = (props: any) => {
    var messageBelongsToCurrentUser = userInfo.objectId == props.currentMessage.user._id;
    var showEllipsis = props.currentMessage.user.name != "Life Oar Guide";
    var hasAlbumRecommendations = props.currentMessage.albumRecommendations != null;

    return (
      <View style={{ maxWidth: "95%" }}>
        <View style={messageBelongsToCurrentUser ? globalStyles.chatMessageActionBarRight : globalStyles.chatMessageActionBarLeft}>
          {showEllipsis &&
            <FontAwesome5 style={{ paddingLeft: messageBelongsToCurrentUser ? 60 : 0 }} name='ellipsis-h' color='black' size={22} onPress={() => showMessageActionModal(props)} />
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "heart") != null && props.currentMessage.sentiments.find(s => s.sentiment == "heart").userIds.length > 0 &&
            <View style={{ paddingRight: 5 }}>
              <FontAwesome5 name='heart' color='red' size={22} onPress={() => showMessageActionModal(props)} />
              <Badge containerStyle={{ position: 'absolute', top: -4, right: 3, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "heart").userIds.length}></Badge>
            </View>
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "thumbs-up") != null && props.currentMessage.sentiments.find(s => s.sentiment == "thumbs-up").userIds.length > 0 &&
            <>
              <View style={{ paddingRight: 5 }}>
                <FontAwesome5 name='thumbs-up' color={seaBlueColor} size={22} onPress={() => showMessageActionModal(props)} />
                <Badge containerStyle={{ position: 'absolute', top: -4, right: 3, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "thumbs-up").userIds.length}></Badge>
              </View>
            </>
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "thumbs-down") != null && props.currentMessage.sentiments.find(s => s.sentiment == "thumbs-down").userIds.length > 0 &&
            <>
              <View style={{ paddingRight: 5 }}>
                <FontAwesome5 name='thumbs-down' color={seaBlueColor} size={22} onPress={() => showMessageActionModal(props)} />
                <Badge containerStyle={{ position: 'absolute', top: -4, right: 3, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "thumbs-down").userIds.length}></Badge>
              </View>
            </>
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "exclamation") != null && props.currentMessage.sentiments.find(s => s.sentiment == "exclamation").userIds.length > 0 &&
            <>
              <View style={{ paddingRight: 10, paddingLeft: 5 }}>
                <FontAwesome5 name='exclamation' color={seaBlueColor} size={22} onPress={() => showMessageActionModal(props)} />
                <Badge containerStyle={{ position: 'absolute', top: -4, right: 3, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "exclamation").userIds.length}></Badge>
              </View>
            </>
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "laugh-squint") != null && props.currentMessage.sentiments.find(s => s.sentiment == "laugh-squint").userIds.length > 0 &&
            <>
              <View style={{ paddingRight: 5 }}>
                <FontAwesome5 name='laugh-squint' color={seaBlueColor} size={22} onPress={() => showMessageActionModal(props)} />
                <Badge containerStyle={{ position: 'absolute', top: -4, right: 3, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "laugh-squint").userIds.length}></Badge>
              </View>
            </>
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "question") != null && props.currentMessage.sentiments.find(s => s.sentiment == "question").userIds.length > 0 &&
            <>
              <View>
                <FontAwesome5 name='question' color={seaBlueColor} size={22} onPress={() => showMessageActionModal(props)} />
                <Badge containerStyle={{ position: 'absolute', top: -4, right: -4, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "question").userIds.length}></Badge>
              </View>
            </>
          }
          {
            props.currentMessage.sentiments != null && props.currentMessage.sentiments.find(s => s.sentiment == "poop") != null && props.currentMessage.sentiments.find(s => s.sentiment == "poop").userIds.length > 0 &&
            <>
              <View style={{ paddingRight: 5 }}>
                <FontAwesome5 name='poop' color='#C4A484' size={22} onPress={() => showMessageActionModal(props)} />
                <Badge containerStyle={{ position: 'absolute', top: -4, right: 3, height: 5, width: 5 }} value={props.currentMessage.sentiments.find(s => s.sentiment == "poop").userIds.length}></Badge>
              </View>
            </>
          }
        </View>
        <Bubble {...props}

        />
        {
          hasAlbumRecommendations &&
          <AlbumRecommendationListComponent listOfAlbums={props.currentMessage.albumRecommendations} />
        }
      </View>
    )
  }

  const showMessageActionModal = (props: any) => {
    setMessageToTakeActionOn(props.currentMessage);
    setBanUserFromGroupRequest(
      {
        requestorId: userInfo.objectId,
        userIdToBan: props.currentMessage.user._id,
        groupId: route.params.groupId
      }
    )
    setBlockUserRequest(
      {
        requestorId: userInfo.objectId,
        userIdToBlock: props.currentMessage.user._id
      }
    )
    setIsMessageActionModalVisible(true)
  }

  const onMessageLongPress = (context, message) => {
    setMessageToImposeSentimentOn(message)
    setIsSentimentModalVisible(true)
  }

  const onSentimentPress = useCallback((sentiment: string, message, userIdPressedSentiment) => {
    wssConnection.invoke("groupChatSentiment", route.params.groupId, message, sentiment, userIdPressedSentiment).catch((err) => console.log(err))
    setIsSentimentModalVisible(false);
  }, [route, navigation])

  const onSend = useCallback((newMessages = []) => {
    const [messageToSend] = newMessages;
    if (wssConnection.state != signalR.HubConnectionState.Connected) {
      startConnection();
    }
    wssConnection.invoke("groupChatMessage", route.params.groupId, newMessages).catch((err) => console.log(err))
  }, [route, navigation])
  const [modalVisible, setModalVisible] = useState(false);
  const handleCheckboxClick = (props) => {
    props.currentMessage._id = new ObjectID().toString();
    props.currentMessage.text = "@Life Oar Guide /imagevariation"
    onSend(props.currentMessage)
    alert("Generate alternate image.")
    //onSend(messages)
    // post a message to the group chat with the image.
  };

  const renderMessageImage = (props) => {
    return (
      <>
        <Pressable onPress={() => handleCheckboxClick(props)} >
          <FontAwesome5 name="bolt" size={24} color="black" />
        </Pressable>
        <View>

          <Image source={{ uri: props.currentMessage.image }} />
        </View>
      </>
    );
  };

  const renderMessageVideo = (props) => {
    if(Platform.OS != "web") {
    return (
        <Video source={{ uri: props.currentMessage.video }} style={{width: "100%", height: 300}}
        paused={false}
        /> 
    )}
    else {
      return(
        <Text>Video not supported on web yet.</Text>
      )
    }
  };

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <>
        <UserInfoContext.Consumer>
          {
            ({ userInfo }: any) => (
              <>
                <View style={globalStyles.centeredContainer}>

                  <View style={globalStyles.chatContainer}>
                    <GiftedChat messages={messages}
                      messageIdGenerator={messages => onGenerateId(messages)}
                      onSend={messages => onSend(messages)}
                      user={{
                        _id: userInfo.objectId,
                        name: userInfo.name,
                        avatar: onRenderAvatar(userInfo.name).toString()
                      }}
                      showUserAvatar={true}
                      alwaysShowSend={true}
                      renderUsernameOnMessage={true}
                      renderAvatar={(props) => onRenderAvatar(props.currentMessage.user.name)}
                      parsePatterns={(linkStyle) => parsePatterns(linkStyle)}
                      onInputTextChanged={onTextChanged}
                      text={inputValue}
                      renderBubble={renderBubble}
                      renderSend={onRenderSend}
                      placeholder="Enter a message or @Life Oar Guide"
                      imageStyle={{ height: 300, width: 200 }}
                      onLongPress={(context, message) => onMessageLongPress(context, message)}
                      renderMessageVideo={(props) => renderMessageVideo(props)}
                    //renderMessageImage={(props) => renderMessageImage(props)}
                    />
                  </View>
                </View>
                <View >
                  <Modal animationType="slide" visible={isUserModalVisible} transparent={true}
                    onRequestClose={() => setIsUserModalVisible(false)}
                    style={globalStyles.modalContainer}>
                    <View style={globalStyles.centeredView}>
                      <View style={globalStyles.titleContainer}>
                        <Text style={globalStyles.modalTitle}>Choose a user to @mention</Text>
                        <Pressable onPress={() => setIsUserModalVisible(false)}>
                          <FontAwesome name="close" color="black" size={22} />
                        </Pressable>
                      </View>
                      <View style={globalStyles.modalView}>
                        <View style={{ alignContent: "flex-start" }}>
                          {groupUsers.map((user) => (
                            <TouchableOpacity key={user.value} onPress={() => handleMentionPress(user.label)}>
                              <Text>{user.label}</Text>
                              <Divider width={3} color={seaBlueColor} />
                            </TouchableOpacity>
                          ))}
                        </View>
                      </View>
                    </View>
                  </Modal>
                  <Modal animationType="slide" visible={isLocationModalVisible} transparent={true}
                    onRequestClose={() => setIsLocationModalVisible(false)}
                    style={globalStyles.modalContainer}>
                    <View style={globalStyles.centeredView}>
                      <View style={globalStyles.titleContainer}>
                        <Text style={globalStyles.modalTitle}>Choose a location to #mention</Text>
                        <Pressable onPress={() => setIsLocationModalVisible(false)}>
                          <FontAwesome name="close" color="black" size={22} />
                        </Pressable>
                      </View>
                      <View style={globalStyles.modalView}>
                        <View style={{ alignContent: "flex-start" }}>
                          {groupLocations.map((location) => (
                            <TouchableOpacity key={location.value} onPress={() => handleLocationPress(location.label)}>
                              <Text style={globalStyles.modalText}>{location.label.trim()}</Text>
                              <Divider width={3} color={seaBlueColor} />
                            </TouchableOpacity>
                          ))}
                        </View>
                      </View>
                    </View>
                  </Modal>
                  <Modal animationType="slide" visible={isMessageActionModalVisible} transparent={true}
                    onRequestClose={() => setIsMessageActionModalVisible(false)}
                    style={globalStyles.modalContainer}>
                    <View style={globalStyles.centeredView}>
                      <View style={globalStyles.titleContainer}>
                        <Text style={globalStyles.modalTitle}>Choose an action.</Text>
                        <FontAwesome name="close" color="black" size={22} onPress={() => setIsMessageActionModalVisible(false)} />
                      </View>
                      <View style={globalStyles.modalView}>
                        <View style={{ alignContent: "flex-start" }}>
                          <View style={{ flexDirection: "row" }}>
                            <Pressable onPress={() => onPressReportMessage()} style={{ flexDirection: "row", alignContent: "center" }}>
                              <FontAwesome name="flag" color="black" size={22} />
                              <Text style={{ paddingLeft: 15 }}>Report Message</Text>
                            </Pressable>
                          </View>
                          <Divider width={3} color={seaBlueColor} />
                          <View style={{ flexDirection: "row" }}>
                            <Pressable onPress={() => onPressBanUserFromGroup()} style={{ flexDirection: "row", alignContent: "center" }}>
                              <FontAwesome5 name="user-minus" color="black" size={22} />
                              <Text style={{ paddingLeft: 15 }}>Ban User from Group</Text>
                            </Pressable>
                          </View>
                          <Divider width={3} color={seaBlueColor} />
                          <View style={{ flexDirection: "row" }}>
                            <Pressable onPress={() => onPressBlockUser()} style={{ flexDirection: "row", alignContent: "center" }}>
                              <FontAwesome5 name="user-shield" color="black" size={22} />
                              <Text style={{ paddingLeft: 15 }}>Block User</Text>
                            </Pressable>
                          </View>
                          <Divider width={3} color={seaBlueColor} />
                        </View>
                      </View>
                    </View>
                  </Modal>
                  <Modal animationType="slide" visible={isSentimentModalVisible} transparent={true}
                    onRequestClose={() => setIsSentimentModalVisible(false)}
                    style={globalStyles.modalContainer}>
                    <View style={globalStyles.centeredView}>
                      <View style={globalStyles.titleContainer}>
                        <Text style={globalStyles.modalTitle}>Choose a sentiment</Text>
                        <Pressable onPress={() => setIsSentimentModalVisible(false)}>
                          <FontAwesome name="close" color="black" size={22} />
                        </Pressable>
                      </View>
                      <View style={globalStyles.modalView}>
                        <View style={{ alignContent: "flex-start", flexDirection: "row", flexWrap: "wrap" }}>
                          <FontAwesome5 name='heart' color='red' size={22} onPress={() => onSentimentPress('heart', messageToImposeSentimentOn, userInfo.objectId)} />
                          <FontAwesome5 style={{ paddingLeft: 10 }} name='thumbs-up' color={seaBlueColor} size={22} onPress={() => onSentimentPress('thumbs-up', messageToImposeSentimentOn, userInfo.objectId)} />
                          <FontAwesome5 style={{ paddingLeft: 10 }} name='thumbs-down' color={seaBlueColor} size={22} onPress={() => onSentimentPress('thumbs-down', messageToImposeSentimentOn, userInfo.objectId)} />
                          <FontAwesome5 style={{ paddingLeft: 10 }} name='exclamation' color={seaBlueColor} size={22} onPress={() => onSentimentPress('exclamation', messageToImposeSentimentOn, userInfo.objectId)} />
                          <FontAwesome5 style={{ paddingLeft: 10 }} name='laugh-squint' color={seaBlueColor} size={22} onPress={() => onSentimentPress('laugh-squint', messageToImposeSentimentOn, userInfo.objectId)} />
                          <FontAwesome5 style={{ paddingLeft: 10 }} name='question' color={seaBlueColor} size={22} onPress={() => onSentimentPress('question', messageToImposeSentimentOn, userInfo.objectId)} />
                          <FontAwesome5 style={{ paddingLeft: 10 }} name='poop' color='#C4A484' size={22} onPress={() => onSentimentPress('poop', messageToImposeSentimentOn, userInfo.objectId)} />
                        </View>
                      </View>
                    </View>
                  </Modal>
                </View>
              </>
            )}
        </UserInfoContext.Consumer>
      </>
    )
  }
}

