import * as React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  ScrollView,
  Platform,
  Dimensions,
} from "react-native";
import AxiosContext from '../components/AxiosContext';
import axios from 'axios';
import { globalStyles } from '../constants/GlobalStyles';
import { BarChart, LineChart, PieChart, PopulationPyramid } from "react-native-gifted-charts";
import { getJournalEntriesForMonth, getEnabledJournalTypes } from '../services/Journal/journalService';
import { useEffect, useState } from 'react';
import { JournalEntryTypesDict } from '../types/oarTypes';
import { LoadingSpinnerComponent } from '../components/LoadingSpinner';
import { seaBlueColor } from '../constants/Colors';
import moment from 'moment';
import { useIsFocused } from '@react-navigation/native';
import { ButtonGroup } from '@rneui/themed';

const windowDimensions = Dimensions.get('window');

export default function MetricsScreen({ route, navigation }) {

  const [isLoading, setIsLoading] = useState(true);
  const [journalData, setJournalData] = useState([]);
  const [enabledJournalTypes, setEnabledJournalTypes] = useState([]);
  const [endResult, setEndResult] = useState([]);
  const isFocused = useIsFocused();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [dimensions, setDimensions] = useState({
    window: windowDimensions,
  });

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window, screen }) => {
        setDimensions({ window });
      },
    );

    var result = [];
    var today = new Date();
    getEnabledJournalTypes().then((enabledTypes) => {
      setEnabledJournalTypes(enabledTypes);
      var types = enabledTypes.map((type) => { return type._id });
      getJournalEntriesForMonth(today.getFullYear(), today.getMonth() + 1).then((journalResponse) => {
        setJournalData(journalResponse);
        types.forEach((journalType) => {
          var dateData = [];
          journalResponse.forEach((entry) => {
            entry.journalEntries.forEach((dataItem) => {
              if (parseInt(dataItem.id) == journalType) {
                if (Platform.OS != "web")
                  dateData.push({ label: moment.utc(entry.date).startOf("day").format("DD"), value: parseInt(dataItem.value) });
                else
                  dateData.push({ label: moment.utc(entry.date).startOf("day").format("MM-DD"), value: parseInt(dataItem.value) });
              }
            });
          });
          result.push({ journalType: journalType, dateData: dateData });
        });
        setEndResult(result);
        setIsLoading(false);
      });
    });
  }, [route, navigation, isFocused]);

  const calculateAverage = (dateData) => {
    if (dateData.length === 0) return 0;

    const sum = dateData.reduce((acc, item) => acc + item.value, 0);
    const average = sum / dateData.length;

    return Math.round(average * 100) / 100;
  };

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    if (enabledJournalTypes.length == 0)
      return (
        <View style={globalStyles.centeredContainer}>
          <Text style={globalStyles.agendaItemTitle}>You have no active Journal settings. Use the cog in the upper left of the Journal screen to get started.</Text>
        </View>
      );
    return (
      <>
        {/* <View>
          <ButtonGroup
            selectedIndex={selectedIndex}
            onPress={(value) => {
              setSelectedIndex(value);
            }}
            buttons={['7d', '30d', '1y']}
          ></ButtonGroup>
        </View> */}
        <ScrollView>
          <View style={globalStyles.centeredContainer}>
            {endResult.map((result) => {
              const averageValue = calculateAverage(result.dateData);
              return (
                <>
                  <View style={globalStyles.centeredContainer}>
                    <Text style={globalStyles.cardTitle}>{JournalEntryTypesDict.types[result.journalType].name}</Text>
                    <Text style={globalStyles.cardSubTitle}>Average: {averageValue}</Text>
                  </View>
                  <View>
                    <LineChart data={result.dateData}
                      maxValue={10}
                      width={dimensions.window.width - 40}
                      color={seaBlueColor}
                      isAnimated={true}
                      spacing={Platform.OS == "web" ? 40 : 20}
                    />
                  </View>
                </>
              );
            })}
          </View>
        </ScrollView>
      </>
    )
  }
}
