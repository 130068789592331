import axios from "axios";

import { BlockUserRequest, LifeOarLogEvent, LifeOarLogEventTypes, LifeOarUser } from "../../types/oarTypes";

export const getAllLifeOarUsers = (includeGuide) => {
	return axios
		.get(`users`, {params: {includeGuide: includeGuide}})
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded All Life Oar Users data.');
		});
};



export const getUserDetails = () => {
	return axios
		.get(`users/details`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded All Life Oar Users data.');
		});
};

export const updateUserLastLogin = (user: LifeOarUser) => {
	axios
		.post('users', user)
		.then(function (response: any) {
			return (response.data);
		})
		.catch(function (error: any) {
			console.log(error.message);
		})
		.finally(function () {
			//setIsLoading(false);
		});
};

export const deleteUser = (objectId: string) => {
	axios
		.delete(`users/${objectId}`)
		.then(function (response: any) {
			return (response.data);
		})
		.catch(function (error: any) {
			alert(error.message);
		})
		.finally(function () {
			//setIsLoading(false);
		});
};

export const logUserLogin = (user: LifeOarUser) => {
	var payLoad: LifeOarLogEvent =
	{
		"eventTypeId": LifeOarLogEventTypes.Login,
		"data": JSON.stringify(user)
	}
	return axios({
		method: 'post',
		url: '/logevents',
		data: payLoad,
		headers: { 'Content-Type': 'application/json; charset=utf-8' }
	});
};


export function blockUser(blockUserRequest: BlockUserRequest, userId: string) {
  return axios
		.post(`users/block`, blockUserRequest)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully created block user request.');
		});
}

export function updateUserColor(color: any) {
	return axios
		.post(`users/profileColor`, {color: color})
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully updated user profile color.');
		});
}
