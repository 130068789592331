import * as AuthSession from 'expo-auth-session';
import * as JSONWebToken from 'jsonwebtoken';
import moment from 'moment';
import { IMessage } from 'react-native-gifted-chat';

export interface UserInfo  {
  name: string,
  objectId: string,
  authTokenInfo: AuthSession.TokenResponse,
  accessTokenInfo: LifeOarAzureADJwtPayload,
  preferences?: UserPreferences,
  expoToken?: string,
  profileColor?: string,
}

export interface UserPreferences {
  showPicture?: boolean;
  alias?: string,
}

export interface LifeOarAzureADJwtPayload extends JSONWebToken.JwtPayload {
  name: string,
  oid: string,
}

export interface LifeOarGroup {
  _id?: string,
  typeId: number,
  name: string,
  owners: string[],
  contributors: string[],
  groupSettings?: GroupSettings | null,
  nickname?: string | null,
}

export const LifeOarGroupTypes = [
  { "value": 0, "label": "Family" },
  { "value": 1, "label": "Social" }
]

export const LifeOarTaskTypes = [
  { "value": 0, "label": "Personal" },
  { "value": 1, "label": "Group" }
]

export const LifeOarEventTypes = [
  { "value": 0, "label": "Personal" },
  { "value": 1, "label": "Group" }
]

export const GroupPermissions = [
  { "value": 0, "label": "Owner" },
  { "value": 1, "label": "Contributor" },
  { "value": 2, "label": "Reader" },
]

export interface GroupChatImage
{
  uri: string,
  height: number,
  width: number
}

export interface ValueLabel {
  value: string,
  label: string
}

export interface GroupSettings {
  isChatModerated: boolean
}

export interface UserSettings{
  _id?: string,
  objectId: string,
  journalSettings: JournalSettings[]
}

export interface JournalEntry{
  _id: string,
  objectId: string,
  journalEntryByDate: JournalEntryByDate[]
}

export interface JournalSettings {
  _id: number,
  enabled: boolean
}

export enum GroupComponents {
  Chat = 0,
  Tasks = 1,
  Schedule = 2,
  Locations = 3,
  Contacts = 4
}

export class JournalEntryTypesDict {
  static readonly types: { [key: number]: { name: string, controlType: string } } = {
    0 : { name: "Sleep", controlType: "scale" },
    1 : { name: "Meds", controlType: "boolean" },
    2 : { name: "Alcohol", controlType: "numeric" },
    3 : { name: "Self Care", controlType: "boolean" },
    4 : { name: "Cannabis", controlType: "boolean" },
    5 : { name: "Exercise", controlType: "boolean" },
    6 : { name: "Food", controlType: "boolean" },
    7 : { name: "Mood", controlType: "scale" },
    8 : { name: "Stress", controlType: "boolean" },
    9 : { name: "Anxiety", controlType: "boolean" },
    10 : { name: "Depression", controlType: "boolean" },
    11 : { name: "Energy", controlType: "boolean" },
    12 : { name: "Pain", controlType: "boolean" },
    13 : { name: "Memory", controlType: "boolean" },
    14 : { name: "Concentration", controlType: "boolean" },
    15 : { name: "Motivation", controlType: "boolean" },
    16 : { name: "Productivity", controlType: "boolean" },
    17 : { name: "Social", controlType: "boolean" },
    18 : { name: "Work", controlType: "boolean" },
    19 : { name: "School", controlType: "boolean" },
    20 : { name: "Relationships", controlType: "boolean" },
    21 : { name: "Family", controlType: "boolean" },
    22 : { name: "Friends", controlType: "boolean" },
    23 : { name: "Romance", controlType: "boolean" },
    24 : { name: "Other", controlType: "boolean" },
    25 : { name: "Poop", controlType: "boolean" },
    26 : { name: "Water", controlType: "boolean" },
    27 : { name: "Diet", controlType: "boolean" },
    28 : { name: "Menstral Cycle", controlType: "boolean" },
  };
}



export class JournalEntry{
  journalSetting: typeof JournalEntryTypesDict.types;
  journalValue: string;
}

export class JournalEntryByDate {
  objectId: string;
  date: string;
  journalEntries: JournalEntry[];
}


export interface SentimentDTO {
  groupId: string,
  message_Id: string,
  sentiment: string,
}

export enum MessageSentiments {
  "thumbsup" = 0,
  "thumbsdown" = 1,
  "exclamation" = 2,
  "laughsquint" = 3,
  "question" = 4
}

export interface LifeOarUser {
  _id?: string,
  objectId: string,
  name: string,
  email: string,
  pushToken?: string,
  lastGoodLogin?: string
  profileColor?: string,
}

export enum LifeOarObjectType{
  Priority = 0,
  State = 1
}

export enum LifeOarEventState{
  New = 0,
  Active = 1,
  Canceled = 2
}

export enum DaysOfTheWeek {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday"
}

export enum MonthsOfTheYear
{
  January= "January",
  February= "February",
  March= "March",
  April= "April",
  May= "May",
  June= "June",
  July= "July",
  August= "August",
  September= "September",
  October= "October",
  November= "November",
  December = "December"
}

export enum LifeOarLogEventTypes {
  Login = 0,
  SomethingElse = 1,
}

export interface LifeOarLogEvent {
  eventTypeId: LifeOarLogEventTypes,
  data: any
}

export enum ReminderType {
  Task = 0,
  Event = 1
}

export interface LifeOarTask {
  _id?: string,
  title: string,
  priority: number,
  state: number,
  type: number,
  assigned?: string,
  assignedName?: string,
  interestedParties?: string[],
  dueDate?: string,
  dateCompleted?: string,
  groupId?: string,
  groupName?: string,
  reminderMinsPrior?: number,
}

export interface LifeOarEvent {
  _id?: string,
  ownerId: string,
  title: string,
  type: number,
  isRecurring: boolean,
  isAllDay: boolean,
  state?: number,
  groupId?: string,
  going?: string[],
  maybeGoing?: string[],
  declined?: string[],
  locationId?: string,
  startDate?: Date,
  startDateTime?: string,
  endDate?: Date,
  lengthInMins?: number,
  reminderMinsPrior?: number,
  recurringPattern?: number,
  mainParticipant?: string
}

export interface ReminderRequest {
  reminderObjectType?: string,
  reminderObjectId?: string,
  ttl?: string
}


export interface LifeOarLocation {
  _id?: string,
  title: string,
  latitude: number,
  longitude: number,
  groupId?: string,
  address?: string
}

export interface ReportedMessage {
  groupId?: string,
  reporterId?: string,
  reportedMessage?: IMessage
}

export interface ReportedPublicMessage {
  reporterId?: string,
  reportedMessage?: IMessage
}

export interface BanUserFromGroupRequest {
  groupId?: string,
  requestorId?: string,
  userIdToBan?: string
}

export interface BanUserRequest {
  requestorId?: string,
  userIdToBan?: string
}

export interface BlockUserRequest {
  requestorId?: string,
  userIdToBlock?: string
}

export const EventRecurranceInterval = [
  { "value": 0, "label": "Daily" },
  { "value": 1, "label": "Weekly" },
  { "value": 2, "label": "Monthly" },
  { "value": 3, "label": "Yearly" },
]

export const EventHoursInDay = [
  { "value": 1, "label": "1" },
  { "value": 2, "label": "2" },
  { "value": 3, "label": "3" },
  { "value": 4, "label": "4" },
  { "value": 5, "label": "5" },
  { "value": 6, "label": "6" },
  { "value": 7, "label": "7" },
  { "value": 8, "label": "8" },
  { "value": 9, "label": "9" },
  { "value": 10, "label": "10" },
  { "value": 11, "label": "11" },
  { "value": 12, "label": "12" },
]

export const EventLengthOptions = [
  { "value": 15, "label": "15 minutes" },
  { "value": 30, "label": "30 minutes" },
  { "value": 45, "label": "45 minutes" },
  { "value": 60, "label": "1 hour" },
  { "value": 90, "label": "1.5 hours" },
  { "value": 120, "label": "2 hours" },
  { "value": 150, "label": "2.5 hours" },
  { "value": 180, "label": "3 hours" },
  { "value": 210, "label": "3.5 hours" },
  { "value": 240, "label": "4 hours" },
  { "value": 300, "label": "5 hours" },
  { "value": 360, "label": "6 hours" },
  { "value": 420, "label": "7 hours" },
  { "value": 480, "label": "8 hours" },
]

export const EventReminderTimeOptions = [
  { "value": 15, "label": "15 minutes prior" },
  { "value": 30, "label": "30 minutes prior" },
  { "value": 45, "label": "45 minutes prior" },
  { "value": 60, "label": "1 hour prior" },
  { "value": 120, "label": "2 hours prior" },
  { "value": 180, "label": "3 hours prior" },
  { "value": 240, "label": "4 hours prior" },
  { "value": 1440, "label": "1 day prior" },
  { "value": 10080, "label": "1 week prior" },
  { "value": 43800, "label": "1 month prior" },
]

export const TaskReminderTimeOptions = [
  { "value": 1, "label": "1 day prior" },
  { "value": 2, "label": "2 days prior" },
  { "value": 3, "label": "3 days prior" },
  { "value": 4, "label": "4 days prior" },
  { "value": 5, "label": "5 days prior" },
  { "value": 7, "label": "1 week prior" },
  { "value": 14, "label": "2 weeks prior" },
  { "value": 21, "label": "3 weeks prior" },
  { "value": 28, "label": "1 month prior" },  // 28 days not technically a month.
  { "value": 56, "label": "2 months prior" }, // 56 days not technically a month.
]

export const EventMinutesToChoose = [
  { "value": 0, "label": "00" },
  { "value": 15, "label": "15" },
  { "value": 30, "label": "30" },
  { "value": 45, "label": "45" },
]

export const ReminderPattern = [
  { "value": 0, "label": "No Reminder" },
  { "value": 1, "label": "5 minutes before" },
  { "value": 2, "label": "10 minutes before" },
  { "value": 3, "label": "15 minutes before" },
  { "value": 4, "label": "30 minutes before" },
  { "value": 5, "label": "60 minutes before" },
  { "value": 6, "label": "1 day before" },
]

export const TaskPriority = [
  { "value": 0, "label": "1 - Important/Urgent" },
  { "value": 1, "label": "2 - Important/Not Urgent" },
  { "value": 2, "label": "3 - Not Important/Urgent" },
  { "value": 3, "label": "4 - Not Important/Not Urgent" },
]

export const TaskState = [
  { "value": 0, "label": "Not Started" },
  { "value": 1, "label": "In Progress" },
  { "value": 2, "label": "Deferred" },
  { "value": 3, "label": "Done" },
  { "value": 4, "label": "Removed" },
]

export interface SpotifyArtist {
  external_urls: {
    spotify: string;
  };
  href: string;
  id: string;
  name: string;
  type: string;
  uri: string;
  // ... other artist properties if needed
}

export interface SpotifyImage {
  height: number;
  url: string;
  width: number;
  // ... other image properties if needed
}

export interface SpotifyAlbum {
  album_type: string;
  artists: SpotifyArtist[];
  external_urls: {
    spotify: string;
  };
  href: string;
  id: string;
  images: SpotifyImage[];
  is_playable: boolean;
  name: string;
  release_date: string;
  release_date_precision: string;
  total_tracks: number;
  type: string;
  uri: string;
  // ... other album properties if needed
}

export interface SpotifyNewReleasesResponse {
  albums: {
    href: string;
    items: SpotifyAlbum[];
    // ... other properties if needed
  };
  // ... other response properties if needed
}