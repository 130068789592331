import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from 'expo-auth-session';
import * as Notifications from 'expo-notifications';
import { StatusBar } from "expo-status-bar";
import * as WebBrowser from 'expo-web-browser';
import jwt_decode from "jwt-decode";
import * as React from 'react';
import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import * as ConfigureAxios from '../hooks/configureAxios';
import { GetAzureADB2CConfig, GetDiscoveryDocument } from '../hooks/configureAzureAd';
import useCachedResources from '../hooks/useCachedResources';
import * as NotificationService from '../services/Notifications/notificationService';
import * as UserService from '../services/Users/userService';
import * as OarTypes from '../types/oarTypes';

import { SpaceMono_400Regular, useFonts } from '@expo-google-fonts/space-mono';
import { seaBlueColor } from '../constants/Colors';

WebBrowser.maybeCompleteAuthSession();

export default function LoginScreen({ navigation }: any) {

  const isLoading = useCachedResources();
  const azureAdProps = GetAzureADB2CConfig();

  const [request, response, promptAsync] = AuthSession.useAuthRequest(
    {
      clientId: azureAdProps.clientId,
      scopes: azureAdProps.scopes,
      redirectUri: azureAdProps.redirectUri,
    },
    GetDiscoveryDocument()
  );


  function logUserLogin(userInfo: any) {
    let userValue: OarTypes.LifeOarUser = {
      "email": userInfo.accessTokenInfo.emails[0],
      "name": userInfo.name,
      "objectId": userInfo.objectId,
      "pushToken": userInfo.expoToken ? userInfo.expoToken : '',
      "lastGoodLogin": new Date().toString(),
      "profileColor": userInfo.profileColor ? userInfo.profileColor : seaBlueColor,
    }
    console.log(userInfo);
    UserService.logUserLogin(userValue);
    UserService.updateUserLastLogin(userValue);
  }

  React.useEffect(() => {
    if (response?.type === 'success') {

      const { code } = response.params;
      (async () => {
        let tokenRes: AuthSession.TokenResponse = await AuthSession.exchangeCodeAsync({
          clientId: azureAdProps.clientId,
          code: code,
          redirectUri: azureAdProps.redirectUri,
          extraParams: {
            code_verifier: request?.codeVerifier || "",
          },
        },
          GetDiscoveryDocument()
        );
        try {
          if (tokenRes.accessToken && tokenRes.accessToken !== null && tokenRes.accessToken !== "") {
            var decodedToken: OarTypes.LifeOarAzureADJwtPayload = jwt_decode(tokenRes.accessToken);
            let token;
            var tokenResult = '';
            if (Platform.OS == "ios") {

              const { status: existingStatus } = await Notifications.getPermissionsAsync();
              let finalStatus = existingStatus;
              if (existingStatus !== 'granted') {
                const { status } = await Notifications.requestPermissionsAsync();
                finalStatus = status;
              }
              if (finalStatus !== 'granted') {
                console.log('Notifications permission not granted');
              }
              else {

                token = NotificationService.registerForPushNotificationsAsync().then((token: string) => {
                  return token.toString();
                });

                tokenResult = Platform.OS == "ios" && finalStatus == 'granted' ? (await token).toString() : '';

              }
            }
            ConfigureAxios.configureAxios(tokenRes.accessToken);
            UserService.getUserDetails().then((results) => {
              var userInfoObject: OarTypes.UserInfo = {
                name: decodedToken.name,
                objectId: decodedToken.oid,
                authTokenInfo: tokenRes,
                accessTokenInfo: decodedToken,
                expoToken: tokenResult,
                profileColor: seaBlueColor,
              };

              AsyncStorage.setItem('life-oar-user_id', JSON.stringify(userInfoObject));


              logUserLogin(userInfoObject);
              navigation.navigate('Splash');
            });

          }
          else {
            console.log("Unable to get a access token based on the code.  ")
          }
        } catch (error) {
          alert("Error getting token: " + error);
        }
      })();
    }
  }, [response]);

  if (isLoading) {
    return null;
  } else {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Life Oar: A Social Life System</Text>
        <Image source={require('../assets/oar-icon-12.png')} style={{ width: 100, height: 100 }} />
        <TouchableOpacity style={styles.loginBtn}
          onPress={async () => {
            //FaceID logic
            let authContextObject = await promptAsync();

            // const faceIdCompatible = await LocalAuthentication.hasHardwareAsync();
            // if(!faceIdCompatible)
            // {

            // }
            // else
            // {
            //   const isEnrolled = await LocalAuthentication.isEnrolledAsync();
            //   if(!isEnrolled)
            //   {
            //     let authContextObject = await promptAsync(); 
            //   }
            //   else
            //   {
            //     let authContextObject = await LocalAuthentication.authenticateAsync();
            //   }
            // }
          }}>
          <Text style={styles.loginText}>Login / Sign up</Text>
        </TouchableOpacity>
        <StatusBar style="auto" />
      </View>
    );
  }
}

const onPressSignUp = () => {
  Linking.openURL('https://lifeoar.b2clogin.com/lifeoar.io/B2C_1_Sign_Up_Sign_In_01/api/CombinedSigninAndSignup/unified?local=signup&csrf_token=WEE2ZmFsVUhrbkVyWUF5N05Tak9ZWGlaSk5vVVgzenZpYnRDNDZSSkNWbkN5cnVEQ0w4YVJyVStvRExnMjArc09TOEhSd0tKUXBvd0NZdXhLUFUyRkE9PTsyMDIzLTA1LTE3VDIyOjE2OjM0LjMyMTIzNzZaOzRKVklwVnZUaTR0dFI2alJVN0pZeXc9PTt7Ik9yY2hlc3RyYXRpb25TdGVwIjoxfQ==&tx=StateProperties=eyJUSUQiOiI3ODQ5MTY3Mi0xMmE2LTQwZjItOGYwOS0wMzMwZDY2M2MzMzMifQ&p=B2C_1_Sign_Up_Sign_In_01');
};

const onPressReset = () => {
  Linking.openURL('https://lifeoar.b2clogin.com/lifeoar.io/oauth2/v2.0/authorize?p=B2C_1_Password_Reset_01&client_id=38a13109-a42f-4470-aac7-90d4ba4072cb&nonce=defaultNonce&redirect_uri=life-oar%3A%2F%2FAuth%2Flogin&scope=openid%20https%3A%2F%2Flifeoar.io%2F11c5a164-a562-4226-aef6-2891e0751ef8%2FLifeOar.Read%20https%3A%2F%2Flifeoar.io%2F11c5a164-a562-4226-aef6-2891e0751ef8%2FLifeOar.Admin&response_type=id_token%20token&prompt=login');
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',

  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  signup_button: {
    height: 30,
    marginBottom: 10,
  },
  loginBtn: {
    minWidth: "30%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#006994",
    marginBottom: 20,
  },
  loginText: {
    color: 'white',
  }
});