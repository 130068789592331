import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TextInput,
  Button,
  TouchableOpacity,
  Pressable,
} from "react-native";
import AxiosContext from '../../components/AxiosContext';
import axios from 'axios';
import { GroupSettings, LifeOarGroup } from "../../types/oarTypes";
import { FontAwesome5 } from '@expo/vector-icons';
import { NewGroupComponent } from '../../components/groups/NewGroupComponent';
import { globalStyles } from "../../constants/GlobalStyles";
import * as GroupService from "../../services/Groups/groupsService";
import { seaBlueColor } from "../../constants/Colors";
import { LoadingSpinnerComponent } from "../../components/LoadingSpinner";
import { EditGroupComponent } from "../../components/groups/EditGroupComponent";

export default function EditGroupScreen({ navigation, route }: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [groupData, setGroupData] = useState<LifeOarGroup|any>();
  const [updatedGroupData, setUpdatedGroupData] = useState<LifeOarGroup>();

  React.useEffect(() => {
    loadGroupDetailData(route.params.groupId);
  }, [route, navigation]);


  const updateGroup = (newGroupData: LifeOarGroup) => {
    axios
      .post('groups', newGroupData)
      .then(function (response: any) {
        setGroupData(response.data);
      })
      .catch(function (error: any) {
        alert(error.message);
      })
      
  };

  const loadGroupDetailData = (groupId: string) => {
    GroupService.getGroupDetail(groupId).then((results: LifeOarGroup) => {
      setGroupData(results);
      navigation.setOptions({
        title: results.name,
        headerTitleStyle: { color: seaBlueColor }
      });
    })
    setIsLoading(false);
  };

  if (isLoading || !groupData) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <View style={globalStyles.container}>
        <EditGroupComponent navigation={navigation} route={route} groupDetail={groupData}></EditGroupComponent>
      </View>
    );
  }
}
