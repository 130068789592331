import axios from "axios";
import { IMessage } from "react-native-gifted-chat";
import { BanUserFromGroupRequest, LifeOarEvent, LifeOarGroup, LifeOarTask, ReportedMessage } from "../../types/oarTypes";

export const getGroups = () => {
	return axios
		.get('groups')
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message)
		})
		.finally(function () {
			console.log('Successfully loaded group data.');
		});
};

export const getGroupDetail = (groupId: string) => {
	return axios
		.get(`groups/${groupId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group detail data.');
		});
}

export const updateChatModerationSetting = (groupId: string, value: boolean) => {
	
	return axios
	.put(`groups/${groupId}/settings/chatModeration`, {groupId: groupId, settingValue: value})
	.then(function (response) {
	
		return (response.data);
	})
	.catch(function (error) {
		console.log(error.message);
	});
}

export const getGroupUsers = (groupId: string) => {
	return axios
		.get(`groups/${groupId}/users`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group user data.');
		});
}

export const saveNewGroupTask = (task: LifeOarTask, groupId: string) => {
	return axios
		.post(`groups/${groupId}/tasks`, task)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		});
};

export const getGroupLocations = (groupId: string) => {
	return axios
		.get(`groups/${groupId}/locations`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group location data.');
		});
}

export const saveNewGroup = (group: LifeOarGroup) => {
	return axios
		.post('groups', group)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully saved new group.');
		});
};

export const updateGroup = (group: LifeOarGroup) => {
	return axios
		.put('groups', group)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully updated group.');
		});
};

export const deleteGroupTask = (groupId: string, taskId: string) => {
	return axios
		.delete(`groups/${groupId}/tasks/${taskId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
};


export const updateGroupTask = (groupId: string, taskId: string, updatedTask: LifeOarTask, originalAssignedToId: string) => {
	return axios
		.put(`groups/${groupId}/tasks/${taskId}`,{"originalAssignedToId" : originalAssignedToId, "task":updatedTask} )
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
}

export const updateGroupEvent = (groupId: string, eventId: string, updatedEvent: LifeOarEvent) => {
	return axios
		.put(`groups/${groupId}/events/${eventId}`,updatedEvent )
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		});
}




export const deleteGroup = (groupId: string) => {
	return axios
		.delete(`groups/${groupId}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log(`Successfully deleted group: ${groupId}`);
		});
};

export function getGroupTasks(groupId: string) {
	return axios
	.get(`groups/${groupId}/tasks`)
	.then(function (response) {
		return (response.data);
	})
	.catch(function (error) {
		console.log(error.message);
	});;
}

export const getGroupScheduleForMonth = (year: number, month: number, groupId: string) => {
	return axios
	.get(`groups/${groupId}/schedule/${year}/${month}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			alert(error.message);
		})
		.finally(function () {
			console.log('Successfully loaded group schedules for the group.');
		});
};


export const reportGroupMessage = (reportedMessage: ReportedMessage) => {
		var groupId = reportedMessage.groupId;
		return axios
			.post(`groups/${groupId}/reportMessage`, reportedMessage)
			.then(function (response) {
				return (response.data);
			})
			.catch(function (error) {
				alert(error.message);
			})
			.finally(function () {
				console.log('Successfully reported message.');
			});
	};


export function requestGroupUserBan(payload: BanUserFromGroupRequest) {
  var groupId = payload.groupId;
		return axios
			.post(`groups/${groupId}/banUserRequest`, payload)
			.then(function (response) {
				return (response.data);
			})
			.catch(function (error) {
				alert(error.message);
			})
			.finally(function () {
				console.log('Successfully requested user be banned.');
			});
}
