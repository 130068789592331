import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { LifeOarGroupTypes, LifeOarGroup, LifeOarUser } from "../../types/oarTypes";
import { Keyboard, SafeAreaView, ScrollView, StatusBar, StyleSheet, TextInput, TouchableOpacity, View, Text } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import { globalStyles } from "../../constants/GlobalStyles";
import { saveNewGroup, updateGroup } from "../../services/Groups/groupsService";
import { Divider, Icon, Input, ListItem } from "@rneui/themed";
import { FontAwesome5 } from '@expo/vector-icons';
import { LoadingSpinnerComponent } from "../LoadingSpinner";
import * as UserService from "../../services/Users/userService";
import { sortArrayByProperty } from "../../helpers/arrayHelpers";

export function EditGroupComponent({ navigation, route, groupDetail }) {
  const [isLoading, setIsLoading] = useState(true);
  const [oGGroupDetail, setOGGroupDetail] = useState<LifeOarGroup>(groupDetail);
  const [newGroupName, setNewGroupName] = useState<string>(groupDetail.name || '');
  const [groupTypeOpen, setGroupTypeOpen] = useState(false);
  const [groupTypeDropdownValue, setGroupTypeDropdownValue] = useState<number>();
  const [groupTypeItems, setGroupTypeItems] = useState<any[]>(LifeOarGroupTypes);

  const [owners, setOwners] = useState<any[]>([]);
  const [ownersOpen, setOwnersOpen] = useState(false);
  const [ownersDropdownValue, setOwnersDropdownValue] = useState<any[]>(oGGroupDetail.owners);

  const [contributors, setContributors] = useState<any[]>([]);
  const [contributorsOpen, setContributorsOpen] = useState(false);
  const [contributorsDropdownValue, setContributorsDropdownValue] = useState<any[]>(oGGroupDetail.contributors);

  useEffect(() => {
    loadUsers().then((results: any[]) => {
      var mappedResults = results.map(x => ({
        value: x.objectId,
        label: `${x.name}`
      })).sort(sortArrayByProperty)
      setOwners(mappedResults);
      setContributors(mappedResults);
      
      setIsLoading(false);
    }
    )
  }, [navigation, route, groupDetail])

  const loadUsers = async () => {
    return UserService.getAllLifeOarUsers(false)
  }

  const handleSubmit = (event: any) => {
    
    var groupName = newGroupName == '' ? oGGroupDetail.name : newGroupName

      var updatedGroup: LifeOarGroup = {
        _id: groupDetail._id,
        name: groupName,
        typeId: groupTypeDropdownValue || groupDetail.typeId,
        owners: ownersDropdownValue,
        contributors: contributorsDropdownValue
      }
      updateGroup(updatedGroup).then((results) => {
        navigation.navigate('GroupDetail', { 'groupId': `${results._id}` })
      });
    
  }

  const handleNameChange = (newName: string) => {
    setNewGroupName(newName);
  }

  const onGroupTypeOpen = useCallback(() => {
    setContributorsOpen(false);
    setOwnersOpen(false);
  }, []);

  const onOwnersOpen = useCallback(() => {
    setContributorsOpen(false);
    setGroupTypeOpen(false);
  }, []);

  const onOwnersValueChange = (value: any[]) => {
    setContributors([...value, ...contributors]);
  };

  const onContributorsOpen = useCallback(() => {
    setOwnersOpen(false);
    setGroupTypeOpen(false);
  }, []);

  if (isLoading || !groupDetail) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <View style={globalStyles.container}>
        <View style={globalStyles.formContainer}>
          <View style={[globalStyles.container, { width: '85%' }]}>
            <TouchableOpacity style={globalStyles.container}>
              <Input style={globalStyles.textInput} maxLength={50} defaultValue={groupDetail.name}
                onChangeText={handleNameChange} inputContainerStyle={globalStyles.textInputContainerStyle} />
            </TouchableOpacity>
            <View style={[globalStyles.container, { zIndex: groupTypeOpen ? 1 : 0 }]}>
              <DropDownPicker open={groupTypeOpen}
                value={groupTypeDropdownValue}
                items={groupTypeItems}
                setOpen={setGroupTypeOpen}
                onOpen={onGroupTypeOpen}
                setValue={setGroupTypeDropdownValue}
                setItems={setGroupTypeItems}
                placeholder={LifeOarGroupTypes[groupDetail.typeId].label}
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
              />
            </View>
            <View style={[globalStyles.container, { zIndex: ownersOpen ? 1 : 0 }]}>
              <DropDownPicker open={ownersOpen}
                itemKey="value"
                value={ownersDropdownValue}
                items={owners}
                setOpen={setOwnersOpen}
                setValue={setOwnersDropdownValue}
                setItems={setOwners}
                onOpen={onOwnersOpen}
                placeholder="Select group owners"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                multiple={true}
              />
            </View>
            <View style={[globalStyles.container, { zIndex: contributorsOpen ? 1 : 0 }]}>
              <DropDownPicker open={contributorsOpen}
                value={contributorsDropdownValue}
                items={contributors}
                setOpen={setContributorsOpen}
                setValue={setContributorsDropdownValue}
                setItems={setContributors}
                onOpen={onContributorsOpen}
                placeholder="Select group contributors"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                multiple={true}
              />
            </View>
          </View>
          <TouchableOpacity style={globalStyles.saveBtn} onPress={handleSubmit}>
            <Text style={globalStyles.loginText}>Update</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

