import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { LifeOarGroupTypes, LifeOarGroup, LifeOarUser, GroupSettings } from "../../types/oarTypes";
import { Keyboard, SafeAreaView, ScrollView, StatusBar, StyleSheet, TextInput, TouchableOpacity, View, Text } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import { globalStyles } from "../../constants/GlobalStyles";
import { saveNewGroup } from "../../services/Groups/groupsService";
import { Divider, Icon, Input, ListItem } from "@rneui/themed";
import { FontAwesome5 } from '@expo/vector-icons';
import { LoadingSpinnerComponent } from "../LoadingSpinner";
import * as UserService from "../../services/Users/userService";
import { sortArrayByProperty } from "../../helpers/arrayHelpers";

export function NewGroupComponent({ navigation }) {
  const [isLoading, setIsLoading] = useState(true);

  const [newGroupName, setNewGroupName] = useState<string>('');

  const [groupTypeOpen, setGroupTypeOpen] = useState(false);
  const [groupTypeDropdownValue, setGroupTypeDropdownValue] = useState<number>();
  const [groupTypeItems, setGroupTypeItems] = useState<any[]>(LifeOarGroupTypes);

  const [owners, setOwners] = useState<any[]>([]);
  const [ownersOpen, setOwnersOpen] = useState(false);
  const [ownersDropdownValue, setOwnersDropdownValue] = useState<any[]>([]);

  const [contributors, setContributors] = useState<any[]>([]);
  const [contributorsOpen, setContributorsOpen] = useState(false);
  const [contributorsDropdownValue, setContributorsDropdownValue] = useState<any[]>([]);

  useEffect(() => {
    console.log('Attempting to get global users list.')
    loadUsers().then((results: any[]) => {
      var mappedResults = results.map(x => ({
        value: x.objectId,
        label: `${x.name}`
      })).sort(sortArrayByProperty)

      setOwners(mappedResults);
      setContributors(mappedResults);
      setIsLoading(false);
    }
    )
  }, [navigation])

  const loadUsers = async () => {
    return UserService.getAllLifeOarUsers(false)
  }

  const handleSubmit = (event: any) => {
    if (newGroupName && groupTypeDropdownValue !== null) {
      
      var groupSettings: GroupSettings = {
        isChatModerated : true,
      };

      var newGroup: LifeOarGroup = {
        name: `${newGroupName}`,
        typeId: groupTypeDropdownValue,
        owners: ownersDropdownValue,
        contributors: contributorsDropdownValue,
        groupSettings: groupSettings
      }
      saveNewGroup(newGroup).then((results) => {
        navigation.navigate('GroupDetail', { 'groupId': `${results._id}` })
      });
    }
    else alert("Life Oar Error: New group name or type submitted but null.")
  }

  const handleNameChange = (newName: string) => {
    setNewGroupName(newName);
  }

  const onGroupTypeOpen = useCallback(() => {
    setContributorsOpen(false);
    setOwnersOpen(false);
  }, []);

  const onOwnersOpen = useCallback(() => {
    setContributorsOpen(false);
    setGroupTypeOpen(false);
  }, []);

  const onOwnersValueChange = (value: any[]) => {
    setContributors([...value, ...contributors]);
  };

  const onContributorsOpen = useCallback(() => {
    setOwnersOpen(false);
    setGroupTypeOpen(false);

  }, []);

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <View style={globalStyles.container}>
        <View style={globalStyles.formContainer}>
          <View style={[globalStyles.container, { width: '85%' }]}>
            <TouchableOpacity style={globalStyles.container}>
              <Input style={globalStyles.textInput} placeholder="Group name" maxLength={50} value={newGroupName} onChangeText={handleNameChange}
                inputContainerStyle={globalStyles.textInputContainerStyle} placeholderTextColor={"black"} />
            </TouchableOpacity>
            <View style={[globalStyles.dropDownContainer, { zIndex: groupTypeOpen ? 1 : 0 }]}>
              <DropDownPicker open={groupTypeOpen}
                value={groupTypeDropdownValue}
                items={groupTypeItems}
                setOpen={setGroupTypeOpen}
                onOpen={onGroupTypeOpen}
                setValue={setGroupTypeDropdownValue}
                setItems={setGroupTypeItems}
                placeholder="Group type"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
              />
            </View>
            <View style={[globalStyles.dropDownContainer, { zIndex: ownersOpen ? 1 : 0 }]}>
              <DropDownPicker open={ownersOpen}
                itemKey="value"
                value={ownersDropdownValue}
                items={owners}
                setOpen={setOwnersOpen}
                setValue={setOwnersDropdownValue}
                setItems={setOwners}
                onOpen={onOwnersOpen}
                placeholder="Select group owners"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                multiple={true}
              />
            </View>
            <View style={[globalStyles.dropDownContainer, { zIndex: contributorsOpen ? 1 : 0 }]}>
              <DropDownPicker open={contributorsOpen}
                value={contributorsDropdownValue}
                items={contributors}
                setOpen={setContributorsOpen}
                setValue={setContributorsDropdownValue}
                setItems={setContributors}
                onOpen={onContributorsOpen}
                placeholder="Select group contributors"
                style={{ borderColor: 'lightgrey' }}
                dropDownContainerStyle={{ borderColor: 'lightgrey' }}
                multiple={true}
              />
            </View>
          </View>
          <TouchableOpacity style={globalStyles.saveBtn} onPress={handleSubmit}>
            <Text style={globalStyles.loginText}>Create</Text>
          </TouchableOpacity>
        </View>

      </View>
    )

  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
    marginHorizontal: 16
  },
  item: {
    backgroundColor: "#f9c2ff",
    padding: 20,
    marginVertical: 8
  },
  header: {
    fontSize: 32,
    backgroundColor: "#fff"
  },
  title: {
    fontSize: 24
  },
  inputContainer: {
    paddingTop: 15
  },

});